/**
 * Menu and Mavigation Element Handlers.
 *
 * @author John Ranby
 * @see    https://github.com/jranby
 */
jQuery(document).ready(function($) {

    /*
     * Menu Toggle Click Hanglefr
     *
     * Bacon ipsum dolor amet tenderloin cow tongue,
     * filet mignon kielbasa brisket salami biltong.
     */
    $('.menu-toggle').on('click', function(e) {
        e.preventDefault();
        $('.primary-menu').toggleClass('open');
        $('body').toggleClass('menu-open');
    });

    // ---------------------------------------------------------------------

    /*
     * Resize Handler
     *
     * Bacon ipsum dolor amet tenderloin cow tongue,
     * filet mignon kielbasa brisket salami biltong.
     */
    $(window).resize(function() {
        var newWidth = $(window).width();
        if (newWidth !== CasaMiaAjax.cachedWidth){
            if ($('.primary-menu').hasClass('open')) {
                $('.primary-menu').removeClass('open');
                $('body').removeClass('menu-open');
            }
            CasaMiaAjax.cachedWidth = newWidth;
        }
    });

    // ---------------------------------------------------------------------

    /*
     * Off Sidebar Click To Close Handling
     *
     * Bacon ipsum dolor amet tenderloin cow tongue,
     * filet mignon kielbasa brisket salami biltong.
     */
    $(document).mouseup(function(evt) {
        if ($('body').hasClass('menu-open') || $('body').hasClass('minicart-shown')) {
            var elements = '#header, .menu-toggle, .primary-menu, .minicart-times, .minicart';
            if (! $(elements).is(evt.target) && $(elements).has(evt.target).length === 0) {
                if ($('.primary-menu').hasClass('open')) {
                    $('.primary-menu').removeClass('open');
                    $('body').removeClass('menu-open');
                    $(document.body).trigger('menu_closed');
                }
                if ($('.minicart').hasClass('shown')) {
                    $('.minicart').removeClass('shown');
                    $('body').removeClass('minicart-shown');
                    $(document.body).trigger('minicart_hidden');
                }
            }
        }
    });
});