/**
 * Slick Carousel Inits + Handles
 *
 * @author John Ranby
 * @see    https://github.com/jranby
 */
jQuery(document).ready(function($) {

    /*
     * Feefo Review Carousel.
     *
     * Bacon ipsum dolor amet tenderloin cow tongue,
     * filet mignon kielbasa brisket salami biltong.
     */
    if ($('#slider-front').length > 0) {
            
        var frontSlide = $('#slider-front');

        frontSlide.slick({
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: true,
            dots: true,
            infinite: true,
            fade: true,
            cssEase: 'linear',
            autoplay: true,
            adaptiveHeight: 1,
            accessibility: 0,
            rows: 0
        });

        frontSlide.on('beforeChange', function (event, slick, currentSlide, nextSlide) {
            $('.slide-pane').addClass('fading');
        });

        frontSlide.on('afterChange', function (event, slick, currentSlide) {
            $('.slide-pane').removeClass('fading');
        });
    }

    // ---------------------------------------------------------------------

    /*
     * Testimonials Carousel
     *
     * Bacon ipsum dolor amet tenderloin cow tongue,
     * filet mignon kielbasa brisket salami biltong.
     */
    if ($('.testimonials').length > 0) {
            
        var testimonialSlider  = $('.testimonials');
        var testimonialPaging  = testimonialSlider.parent().find('.paging');
        var testimonialPrevArr = testimonialPaging.find('.prev');
        var testimonialNextArr = testimonialPaging.find('.next');

        testimonialSlider.slick({
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: true,
            dots: false,
            infinite: true,
            prevArrow: testimonialPrevArr,
            nextArrow: testimonialNextArr,
            appendArrows: testimonialPaging,
            adaptiveHeight: 1,
            accessibility: 0,
            rows: 0
        });

        testimonialSlider.on('beforeChange', function (event, slick, currentSlide, nextSlide) {
            $('.client-logos a').removeClass('current d-none');
            var currThumb = $('.client-logos a[data-slide=' + nextSlide + ']').addClass('current d-none').appendTo('.client-logos');
        });

        $('.client-logos a').on('click', function(e) {
            e.preventDefault();
            var slideIndex = $(this).attr('data-slide');
            $('.client-logos a').removeClass('current');
            $(this).addClass('current');
            testimonialSlider.slick('slickGoTo', parseInt(slideIndex));
        });

        // on before slide change change pager to current slide
        testimonialSlider.on('beforeChange', function (event, slick, currentSlide, nextSlide) {
            var s = '000000000' + (nextSlide + 1);
            var mySlideNumber = s.substr(s.length-2);
            testimonialPaging.find('.current-slide').text(mySlideNumber);
        });
    }
});