/**
 * Form Handlers
 *
 * @author John Ranby
 * @see    https://github.com/jranby
 */
jQuery(document).ready(function($) {

    /*
     * Fire datepicker on inputs that require it
     * and disable any shut dates on datepicker.
     *
     * Bacon ipsum dolor amet tenderloin cow tongue,
     * filet mignon kielbasa brisket salami biltong.
     */
    if (typeof CasaMiaAjax !== 'undefined') {
        if ($('#reservation-date').length > 0) {
            
            var default_date = new Date();
            var day_of_week = default_date.getDay() + 1;
            var shut_dates = CasaMiaAjax.shut_dates;
            var intervals = CasaMiaAjax.opening_hours[day_of_week].intervals;

            if (typeof intervals === 'undefined') {
                default_date.setDate(default_date.getDate() + 1);
            }

            $('#reservation-date').datepicker({
                minDate: 0,
                inline: true,
                showOtherMonths: true,
                dateFormat: 'dd/mm/yy',
                minDate: default_date,
                defaultDate: default_date,
                beforeShowDay: function(date){
                    var string = $.datepicker.formatDate('yy-mm-dd', date);
                    return [ shut_dates.indexOf(string) == -1 ]
                },
                onSelect: function(date, inst){
                    var d = new Date(inst.selectedYear, inst.selectedMonth, parseInt(inst.selectedDay, 10));
                    var dow = d.getDay();
                    $.ajax({
                        url: CasaMiaAjax.ajax_url,
                        data: {
                            action: 'cmo_get_dow_intervals',
                            day_of_week: dow === 0 ? 7 : dow
                        },
                        type: 'POST',
                        dataType: 'json',
                        beforeSend: function(data) {
                            console.log(data);
                        },
                        success: function(data) {
                            if (data.length) {
                                var len, par;
                                var sel = document.getElementById('reservation-time');
                                
                                len = sel.options.length - 1;
                                for (var i=len; i; i--) {
                                    par = sel.options[i].parentNode;
                                    par.removeChild(sel.options[i]);
                                }

                                data.reverse();
                                len = data.length;
                                for (var i=len; i; i--) {
                                    var opt = document.createElement('option');
                                    opt.appendChild(document.createTextNode(data[i-1]));                                    
                                    opt.value = data[i-1];
                                    sel.appendChild(opt);
                                }
                            }
                        },
                        error: function(response) {
                            console.log(response);
                        }
                    })
                }
            });
        }
    }

    // ---------------------------------------------------------------------

    /*
     * Ajax Generic Form Handler
     *
     * Bacon ipsum dolor amet tenderloin cow tongue,
     * filet mignon kielbasa brisket salami biltong.
     */
    $(function() {
         
        var form     = $('.needs-validation:not(#newsletter-signup-form):not(#quick-reservation-form)');
        var action   = form.attr('action');
        var formMssg = form.find('.form-messages');
        var sendBttn = form.find('.btn-send');
        var spinner  = sendBttn.find('.fa-spinner');

        $(form).submit(function(e) {

            e.preventDefault();
            e.stopPropagation();

            if ($(this).get(0).checkValidity() === true) {

                $(sendBttn).prop('disabled', true);
                $(spinner).removeClass('d-none');

                var formData = $(this).serialize();

                grecaptcha.ready(function() {
                    grecaptcha.execute('6LdbMbYZAAAAALphIQgyv5u2_4sWLk-Z4QMtmu5o', {
                        action: action
                    }).then(function(token) {

                        if (! token) {
                            grecaptcha.reset();
                            return null;
                        }

                        var gRecaptchaResponse = $('#g-recaptcha-response-data');
                        gRecaptchaResponse.val(token);

                        $.ajax({
                            type: 'POST',
                            url: CasaMiaAjax.ajax_url,
                            data: {
                                'action': action,
                                'formdata': formData,
                                'gRecaptchaResponse': gRecaptchaResponse.val()
                            },
                            beforeSend: function() {
                                console.log(this.data);
                                $(sendBttn).prop('disabled', true);
                                $(spinner).removeClass('d-none');
                            },
                        })
                        .done(function(response) {
                            $(sendBttn).prop('disabled', false);
                            $(spinner).addClass('d-none');
                            $(formMssg).removeClass('d-none error').addClass('success').text(response);
                            $('.form-control').val('');
                            $('.custom-select').prop('selectedIndex', 0);
                            $('.custom-control-input').prop('checked', 'checked');
                            $(form).removeClass('was-validated');
                            CasaMiaAjax.scrollToEl();
                        })
                        .fail(function(data) {
                            $(sendBttn).prop('disabled', false);
                            $(spinner).addClass('d-none');
                            $(formMssg).removeClass('d-none success').addClass('error');
                            if (data.responseText !== '') {
                                $(formMssg).text(data.responseText);
                            } else {
                                $(formMssg).text('An error occured and your message could not be sent. Please try again.');
                            }
                            CasaMiaAjax.scrollToEl();
                        });
                    });
                });
            }
            $(this).addClass('was-validated');
        });
    });

    // ---------------------------------------------------------------------

    /*
     * Ajax Quick Reservation Form Handler
     *
     * Bacon ipsum dolor amet tenderloin cow tongue,
     * filet mignon kielbasa brisket salami biltong.
     */
    $(function() {
        
        var quick    = $('#quick-reservation-form');
        var sendBttn = quick.find('.btn-send');
        var spinner  = sendBttn.find('.fa-spinner');

        $(quick).on('submit', function(e) {

            e.preventDefault();
            e.stopPropagation();

            if ($(quick).get(0).checkValidity() === true) {

                $(sendBttn).prop('disabled', true);
                $(spinner).removeClass('d-none');

                var formData = $(this).serialize();

                grecaptcha.ready(function() {
                    grecaptcha.execute('6LdbMbYZAAAAALphIQgyv5u2_4sWLk-Z4QMtmu5o', {
                        action: 'cmo_quick_reservation_form_handler'
                    }).then(function(token) {

                        if (! token) {
                            grecaptcha.reset();
                            return null;
                        }

                        var gRecaptchaResponse = $('#g-recaptcha-response-data');
                        gRecaptchaResponse.val(token);

                        $.ajax({
                            type: 'POST',
                            url: CasaMiaAjax.ajax_url,
                            data: {
                                'action': 'cmo_quick_reservation_form_handler',
                                'formdata': formData,
                                'gRecaptchaResponse': gRecaptchaResponse.val()
                            },
                            beforeSend: function(data) {
                                $(sendBttn).prop('disabled', true);
                                $(spinner).removeClass('d-none');
                            },
                            success: function(data, textStatus, jqXHR){
                                window.location = data;
                            }
                        })
                        .done(function(response) {
                            $(sendBttn).prop('disabled', false);
                            $(spinner).addClass('d-none');
                            $('.form-control').val('');
                            $('.custom-select').prop('selectedIndex', 0);
                            $(quick).removeClass('was-validated');
                        })
                        .fail(function(data) {
                            $(sendBttn).prop('disabled', false);
                            $(spinner).addClass('d-none');
                        });
                    });
                });
            }
            $(quick).addClass('was-validated');
        });
    });

    // ---------------------------------------------------------------------

    /*
     * Ajax Signup Mailchimp Form Handler
     *
     * Bacon ipsum dolor amet tenderloin cow tongue,
     * filet mignon kielbasa brisket salami biltong.
     */
    $(function() {
    
        var signup   = $('#newsletter-signup-form');
        var formMssg = signup.find('.form-messages');
        var sendBttn = signup.find('.btn-send');
        var spinner  = sendBttn.find('.fa-spinner');

        $(document).on('submit', '#newsletter-signup-form', function(e) {

            e.preventDefault();
            e.stopPropagation();

            if ($(signup).get(0).checkValidity() === true) {
                var formData = $(signup).serialize();

                $.ajax({
                    type: 'POST',
                    url: CasaMiaAjax.ajax_url,
                    data: {
                        'action': 'cmo_newsletter_signup_form_handler',
                        'formdata': formData
                    },
                    beforeSend: function() {
                        $(sendBttn).prop('disabled', true);
                        $(spinner).removeClass('d-none');
                    },
                    success: function(data, textStatus, jqXHR) {
                        if (jqXHR.status == 200) {
                            $('.form-control').val('');
                            $('#signup_terms').prop('checked', false);                            
                            $(signup).removeClass('was-validated');
                            setTimeout(function() {
                                $('#newsletter-signup-modal').modal('hide');
                                $(formMssg).removeClass('error success').addClass('d-none').text('');
                            }, 5000);
                        }
                    },
                })
                .done(function(response) {
                    $(sendBttn).prop('disabled', false);
                    $(spinner).addClass('d-none');
                    $(formMssg).removeClass('d-none error').addClass('success').text(response);
                })
                .fail(function(data) {
                    $(sendBttn).prop('disabled', false);
                    $(spinner).addClass('d-none');
                    $(formMssg).removeClass('d-none success').addClass('error');
                    if (data.responseText !== '') {
                        $(formMssg).text(data.responseText);
                    } else {
                        $(formMssg).text('An error occured and your message could not be sent. Please try again.');
                    }
                });
            }
            $(signup).addClass('was-validated');
        });
    });

    // ---------------------------------------------------------------------

    /*
     * Fire datepicker on inputs that require it
     *
     * Bacon ipsum dolor amet tenderloin cow tongue,
     * filet mignon kielbasa brisket salami biltong.
     */
    if ($('#birth-day').length > 0) {

        var dobString;

        var selectedDay   = 'DD',
            selectedMonth = 'MM',
            selectedYear  = 'YYYY';

        $('#loyalty-card-registration-form').on('change', 'select#birth-year, select#birth-month, select#birth-day', function(evt) {

            if ($(evt.target).attr('id') === 'birth-year') {
                selectedYear = $(evt.target).val();
            } else if ($(evt.target).attr('id') === 'birth-month') {
                selectedMonth = $(evt.target).val();
            } else if ($(evt.target).attr('id') === 'birth-day') {
                selectedDay = $(evt.target).val();
            }
            
            dobString = selectedYear + '-' + selectedMonth + '-' + selectedDay;

            $('#dob').val(dobString);
        });
    }
});