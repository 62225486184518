/*
 * Init Gmaps
 *
 * Lorem ipsum dolor sit amet, consectetur adipiscing
 * elit. Proin aliquam commodo quam etiamx imperdiet.
 */
window.initCourseMap = function initCourseMap() {

    // CasaMiaAjax is required to continue
    if (typeof CasaMiaAjax === 'undefined') {
        return false;
    }

    var marker,
        markers = [],
        ele = document.getElementById('map'),
        simple = document.getElementById('simple-map'),
        location = document.getElementById('course-location'),
        infoWindow = new google.maps.InfoWindow(),
        iconBase = CasaMiaAjax.publicUrl + 'img/content/markers/';

    var style = [
        {   
            // poi stands for point of interest -
            // don't show these lables on the map 
            featureType: "poi",
            elementType: "labels",
            stylers: [
                {visibility: "off"}
            ]
        },
        {
            // don't show highways lables on the map
            featureType: 'road.highway',
            elementType: 'labels',
            stylers: [
                {visibility: "off"}
            ]
        }, 
        {   
            // don't show local road lables on the map
            featureType: "road.local", 
            elementType: "labels.icon", 
            stylers: [
                {visibility: "off"} 
            ] 
        },
        { 
            // don't show arterial road lables on the map
            featureType: "road.arterial", 
            elementType: "labels.icon", 
            stylers: [
                {visibility: "off"}
            ] 
        },
        {
            // don't show road lables on the map
            featureType: "road",
            elementType: "geometry.stroke",
            stylers: [
                {visibility: "off"}
            ]
        }
    ];

    if (ele != null) {

        // CasaMiaLocations is required to continue
        if (typeof CasaMiaLocations === 'undefined') {
            return false;
        }

        var map        = ele;
        var locationId = location.getAttribute('data-location-id');
        var amenities  = CasaMiaLocations[locationId].amenities;

        var latitude   = map.getAttribute('data-latitude'),
            longitude  = map.getAttribute('data-longitude');

        map = new google.maps.Map(map, {
            zoom: 15,
            center: new google.maps.LatLng(latitude, longitude),
            mapTypeId: 'roadmap',
            disableDefaultUI: false,
            mapTypeControl: false,
            streetViewControl: false,
            scrollwheel: false,
            styles: style
        });

        var zoomInButton = document.getElementById('gmap-zoom-in'),
            zoomOutButton = document.getElementById('gmap-zoom-out');

        // marker
        marker = new google.maps.Marker({
            position: {lat: parseFloat(latitude), lng: parseFloat(longitude)},
            map: map,
            icon: iconBase + 'cmo-pin.png',
            zIndex: google.maps.Marker.MAX_ZINDEX + 1
        });

        if (amenities) {
            for (var i = 0; i < amenities.length; i++) {
                var icon = {
                    url: iconBase + amenities[i].type + '.png',
                }
                var slug = 'https://www.google.com/maps/search/?api=1&query=' + amenities[i].latitude + ',' + amenities[i].longitude;
                var html =
                    '<div class="info-window-content">' +
                    '<div class="info-window-body">' +
                    '<div class="title">' + amenities[i].name + '</div>' +
                    '</div>' +
                    '<div class="info-window-footer">' +
                    '<a class="view-amenity" target="_blank" href="' + slug + '">View amenity</a>' +
                    '</div>' +
                    '</div>';
                // marker
                marker = new google.maps.Marker({
                    position: {lat: parseFloat(amenities[i].latitude), lng: parseFloat(amenities[i].longitude)},
                    map: map,
                    icon: icon,
                    html: html
                });
                markers.push(marker);
            }

            for (let i = 0; i < markers.length; i++) {
                google.maps.event.addListener(markers[i], 'click', function() {
                    infoWindow.setContent(markers[i].html);
                    infoWindow.open(map, markers[i]);
                });
            }

            // Setup the click event listener - zoomIn
            if (zoomInButton != null) {
                google.maps.event.addDomListener(zoomInButton, 'click', function() {
                    map.setZoom(map.getZoom() + 1);
                });
            }
            // Setup the click event listener - zoomOut
            if (zoomOutButton != null) {
                google.maps.event.addDomListener(zoomOutButton, 'click', function() {
                    map.setZoom(map.getZoom() - 1);
                });
            }
        }
    }
    
    if (simple != null) {
        
        var lat  = simple.getAttribute('data-latitude'),
            lng  = simple.getAttribute('data-longitude'),
            zoom = simple.getAttribute('data-zoom');
        
        var simplemap = new google.maps.Map(simple, {
                zoom: zoom ? parseFloat(zoom) : 14,
                center: new google.maps.LatLng(lat, lng),
                mapTypeId: 'roadmap',
                disableDefaultUI: false,
                mapTypeControl: false,
                streetViewControl: false,
                scrollwheel: false,
                styles: style
            });
        
        var pin = new google.maps.Marker({
            position: {lat: parseFloat(lat), lng: parseFloat(lng)},
            map: simplemap,
            icon: iconBase + 'cmo-pin.png'
        });
    }
}