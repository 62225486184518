/*
 * Woocommerce Functions + Handlers
 *
 * @author John Ranby
 * @see    https://github.com/jranby
 */
jQuery(document).ready(function($) {
    
    /*
     * @TODO
     *
     * Bacon ipsum dolor amet tenderloin cow tongue,
     * filet mignon kielbasa brisket salami biltong.
     */
    $(document.body).on('click', '.basket-link', function(e) {
        var count = $(this).find('.basket-count');
        if (! $(count).hasClass('d-none')) {
            e.preventDefault();
            if (! $('.minicart').hasClass('shown')) {
                $('.minicart').addClass('shown');
                $('body').addClass('minicart-shown');
                $(document.body).trigger('minicart_shown');
            }
        }
    });


    /*
     * Resize Handler
     *
     * Bacon ipsum dolor amet tenderloin cow tongue,
     * filet mignon kielbasa brisket salami biltong.
     */
    $(window).resize(function() {
        var newWidth = $(window).width();
        if (newWidth !== CasaMiaAjax.cachedWidth){
            if ($('.minicart').hasClass('shown')) {
                $('.minicart').removeClass('shown');
                $('body').removeClass('minicart-shown');
                $(document.body).trigger('minicart_hidden');
            }
            CasaMiaAjax.cachedWidth = newWidth;
        }
    });


    /*
     * @TODO
     *
     * Bacon ipsum dolor amet tenderloin cow tongue,
     * filet mignon kielbasa brisket salami biltong.
     */
    $(document.body).on('click', '.minicart-times', function(e) {
        e.preventDefault();
        if ($('.minicart').hasClass('shown')) {
            $('.minicart').removeClass('shown');
            $('body').removeClass('minicart-shown');
            $(document.body).trigger('minicart_hidden');
        }        
    });


    /*
     * @TODO
     *
     * Bacon ipsum dolor amet tenderloin cow tongue,
     * filet mignon kielbasa brisket salami biltong.
     */
    $(document.body).on('removed_from_cart', function(e) {
        if (Cookies.get('woocommerce_items_in_cart') > 0) {
            $('.minicart').addClass('shown');
        } else {
            $('body').removeClass('minicart-shown');
            if ($('body').hasClass('woocommerce-cart')) {
                location.reload();
            }
        }
    });


    /*
     * Add an extra class to the modal backdrop to make it customizable
     *
     * Bacon ipsum dolor amet tenderloin cow tongue,
     * filet mignon kielbasa brisket salami biltong.
     */
    $('#delivery-info-modal').on('show.bs.modal', function(e) {
        setTimeout(function(){
            $('.modal-backdrop').addClass('for-delivery-info');
        });
    }); 


    /*
     * Add an extra class to the modal backdrop to make it customizable
     *
     * Bacon ipsum dolor amet tenderloin cow tongue,
     * filet mignon kielbasa brisket salami biltong.
     */
    $('#newsletter-signup-modal').on('show.bs.modal', function(e) {
        setTimeout(function(){
            $('.modal-backdrop').addClass('for-newsletter-signup');
        });
    }); 

    /*
     * @TODO
     *
     * Bacon ipsum dolor amet tenderloin cow tongue,
     * filet mignon kielbasa brisket salami biltong.
     */
    $(document.body).on('added_to_cart', function(e) {
        $('.minicart').addClass('shown');
        $('body').addClass('minicart-shown');
    });

    
    /*
     * @TODO
     *
     * Bacon ipsum dolor amet tenderloin cow tongue,
     * filet mignon kielbasa brisket salami biltong.
     */
    if ($('body').hasClass('woocommerce-account')) {
        $('.woocommerce-account-salutation .toggle').on('click', function(e) {
            e.preventDefault();
            $(this).parent().nextAll().toggle();
        });
        $(window).resize(function() {
            var width = window.innerWidth;
            if (width >= 768) {
                $('.woocommerce-account-salutation').nextAll().show();
            } else {
                $('.woocommerce-account-salutation').nextAll().hide();
            }
        });        
    };


    /*
     * Dynamic quantity inputs inserted and wrapped
     * around the existing quantity buttons in place.
     *
     * @param {Object} evt The JQuery event
     */
    $(document).on('click', '.quantity-button', function(evt) {
        var qtyButton = $(this),
            oldValue  = qtyButton.closest('.quantity-wrap').find('.qty').val();
        if (qtyButton.hasClass('increment')) {
            var newVal = parseFloat(oldValue) + 1;
        } else {
            if (oldValue > 0) {
                var newVal = parseFloat(oldValue) - 1;
                if (newVal == 0)
                    newVal = 1;
            } else {
                newVal = 1;
            }
        }
        qtyButton.closest('.quantity-wrap').find('.qty').val(newVal);
        $('[name="update_cart"]').prop('disabled', false);
    });


    /*
     * Sets a cookie and hides the store notice
     * when the dismiss button is clicked.
     *
     * @param {Object} evt The JQuery event
     */
    $('.restaurant-order-button.view').click(function(evt) {
        evt.preventDefault();
        var order_id = $(this).attr('data-object-id');
        var restaurant_order = $('#restaurant-order-' + order_id);
        $('tr.showing').removeClass('showing');
        $('.selected').not(this).removeClass('selected').text('View');
        $('.restaurant-order').not(restaurant_order).addClass('d-none');
        restaurant_order.toggleClass('d-none');
        if ($(evt.target).text() == 'View') {
            $(evt.target).text('Hide');
            $(evt.target).addClass('selected');
            $(this).closest('tr').addClass('showing');
        } else {
            $(evt.target).text('View');
            $(evt.target).removeClass('selected');
            $(this).closest('tr').removeClass('showing');
        }
    });


    /*
     * Deletion Confirmation Handlers.
     *
     * Lorem ipsum dolor sit amet, consectetur adipiscing
     * elit. Proin aliquam commodo quam etiamx imperdiet.
     */
    $('[name="restaurant-order-submit"]').click(function() {
        return confirm("Are you sure you want to complete this order? This can't be undone.");
    });


    /*
     * Sets a cookie and hides the store notice
     * when the dismiss button is clicked.
     *
     * @param {Object} evt The JQuery event
     */
    $('.woocommerce-store-notice__dismiss-link').click(function(evt) {
        $('body').removeClass('woocommerce-demo-store');
    });


    /*
     * Takeaway Delivery Handler
     *
     * Bacon ipsum dolor amet tenderloin cow tongue,
     * filet mignon kielbasa brisket salami biltong.
     *
     * @param {Object} evt The JQuery event
     */
    $(document).on('change', '.takeaway-delivery-options select', function(evt) {
        if ($(this).val() === 'select') {
            $('.takeaway-delivery-time').removeClass('d-none').find('select').prop('selectedIndex', 1);
        } else {
            $('.takeaway-delivery-time').addClass('d-none').find('select').prop('selectedIndex', 0);
        }
    });

    //$('.takeaway-delivery-options select').select2();
});